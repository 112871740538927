<template>
  <sdPageHeader title="Relatie toevoegen">
  </sdPageHeader>
  <Main>
    <Relation
        :show-card-save-button="false"
        :show-global-save-button="true"
        type="ADD"
    ></Relation>
  </Main>
</template>

<script>
import {defineComponent} from 'vue';
import Relation from "../../components/relation/Relation.vue";
import {Main} from "../styled";

export default defineComponent({
  name: 'RelationEdit',
  components: {
    Relation,
    Main,
  },
});
</script>
